import React from 'react'
import { Helmet } from 'react-helmet';

const Facebook = ({ url, type = 'website', title, description, image, imageAlt, locale, siteName }) => {
    return (
        <Helmet>
            <meta property="og:site_name" content={siteName}></meta>

            <meta property="og:locale" content={locale} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:alt" content={imageAlt} />
        </Helmet>
    )
}

export default Facebook;