import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Facebook from './Facebook';
import Twitter from './Twitter';

const SEO = ({ title, description, pathname, image, imageAlt }) => {
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultImageAlt,
    siteLanguage,
    ogLanguage,
    twitter,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    imageAlt: imageAlt || defaultImageAlt,
    url: `${siteUrl}${pathname || ''}`,
  };

  return (
    <>
      <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle}>
        {title && <title>{title}</title>}
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="theme-color" content="#FFFBF7"></meta>
        {seo.image && <meta name="image" content={seo.image} />}
      </Helmet>
      <Facebook
        url={seo.url}
        title={seo.title}
        description={seo.description}
        image={seo.image}
        imageAlt={seo.imageAlt}
        locale={ogLanguage}
        siteName={defaultTitle}
      ></Facebook>
      <Twitter
        title={seo.title}
        description={seo.description}
        image={seo.image}
        imageAlt={seo.imageAlt}
        username={twitter}
      ></Twitter>
    </>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        defaultImageAlt: imageAlt
        siteLanguage
        ogLanguage
        twitter
      }
    }
  }
`;
